import { put, select, takeLatest } from 'redux-saga/effects';
import selectors from '../selectors';

function* setTipsAmount({ payload }) {
  try {
    let amount = payload;
    const isCustomerCommission = yield select(selectors.getCustomerCommission);

    if (Number(amount) < 0) amount = 0;
    yield put({
      type: 'SET_SUM_SUCCESS',
      payload: {
        sum: isCustomerCommission ? (amount / 0.97).toFixed(2) : amount, // todo round
      },
    });
    yield put({
      type: 'SET_TIPS_AMOUNT_SUCCESS',
      payload: amount,
    });
  } catch (error) {
    // yield put()
  }
}

function* setCustomerCommission({ payload }) {
  try {
    const isCustomerCommission = payload;
    const amount = yield select(selectors.getTipsAmount);

    yield put({
      type: 'SET_SUM_SUCCESS',
      payload: {
        sum: isCustomerCommission ? (amount / 0.97).toFixed(2) : amount,
      },
    });
  } catch (error) {
    // yield put()
  }
}

export default function* tips() {
  yield takeLatest('SET_TIPS_AMOUNT', setTipsAmount);
  yield takeLatest('SET_CUSTOMER_COMMISSION', setCustomerCommission);
}
