import axios from 'axios';
import i18n from '../../i18n';
import { URL_GET_TERMINAL, URL_GET_TRANSACTION } from '../../utils/variables';

const getTerminal = (payId) => {
  if (payId) {
    switch (payId[0]) {
      case 't':
      case 'T': {
        const termId = payId.substr(1);
        return axios.post(URL_GET_TERMINAL, { term_id: termId })
          .then(({ data }) => {
            const { response, status } = data;
            if (status === 0) {
              return Promise.resolve(response);
            }
            document.title = i18n.t('Error');
            return Promise.reject(new Error(i18n.t('loadPayment.There is no such terminal')));
          });
      }
      case 'p':
      case 'P': {
        const tranId = payId.substr(1);
        return axios.post(URL_GET_TRANSACTION, { tran_id: tranId }).then(({ data }) => {
          const { response, status } = data;
          if (status === 0) {
            // const { payment } = response;
            response.terminal.isFlexible = false;
            document.title = `${i18n.t('loadPayment.Payment in the amount of')} ${response.payment.amount} ₽ ${i18n.t('loadPayment.by terminal')} ${response.terminal.name}`;
            return Promise.resolve(response);
          }
          return Promise.resolve({ ...response, rdStatus: true });
        });
      }
      default: {
        document.title = i18n.t('Error');
        return Promise.reject(new Error(i18n.t('loadPayment.There is no such payment')));
      }
    }
  } else {
    document.title = i18n.t('Error');
    return Promise.reject(new Error(i18n.t('loadPayment.There is no such terminal')));
  }
};

export default getTerminal;
