const initialState = {
  loading: true,
  participants: [],
};

const getParticipants = (state) => ({
  ...state, loading: true,
});

const getParticipantsSuccess = (state, payload) => ({
  ...state, participants: payload, loading: false,
});

const getParticipantsFail = (state) => ({
  ...state, loading: false,
});

function sbp(state = initialState, action) {
  switch (action.type) {
    case 'GET_PARTICIPANTS':
      return getParticipants(state);
    case 'GET_PARTICIPANTS_SUCCESS':
      return getParticipantsSuccess(state, action.payload);
    case 'GET_PARTICIPANTS_FAIL':
      return getParticipantsFail(state);
    default:
      return state;
  }
}

export default sbp;
