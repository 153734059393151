import axios from 'axios';
import { URL_GET_DEBT } from '../../utils/variables';

export const getApproval = (values, recipient, terminal_id, service_id) => {
  const attributes = [];
  for (const key in values) {
    if (key !== 'id1') {
      attributes.push({ name: key, value: values[key] });
    }
  }

  return axios.post(URL_GET_DEBT, {
    recipient,
    terminal_id,
    service_id,
    account: values.id1,
    attributes,
  }).then(({ data }) => {
    if (data.status === 0 && data.response) {
      const { code } = data.response;
      const errorText = {
        1000: 'Данные не прошли проверку',
        1001: 'Данные не прошли проверку через сервер поставщика',
        1002: 'Приём платежа временно запрещён провайдером',
        1003: 'Проверка через сервер поставщика недоступна. Вы можете совершить платеж, если уверены в правильности параметров',
        1004: 'Приём платежей временно запрещён провайдером',
        1005: 'Приём платежей запрещён провайдером',
      };
      if (code === '0'
                || code === '1002'
                || code === '1003'
                || code === '1004') {
        const { response } = data;
        const amount = response.attribute && response.attribute.length && response.attribute.map((i) => i.name).indexOf('#sum') !== -1 ? response.attribute[response.attribute.map((i) => i.name).indexOf('#sum')].value : 0;
        // setAttribute(response.attribute);
        const serverAttribute = [];
        const serverSetfields = {};
        response.attribute.forEach((i) => {
          if (values[i.name] || values[i.name] === null) {
            serverSetfields[i.name] = i.value;
          } else {
            serverAttribute.push(i);
          }
        });
        return Promise.resolve({
          values: { ...values, ...serverSetfields },
          attribute: serverAttribute,
          serviceForm: { ...values, ...serverSetfields, ...serverAttribute.filter((i) => i.name !== '#sum') },
          amount,
        });
      }
      return Promise.reject(new Error(errorText[code]));
    }
    return Promise.reject(new Error('Неизвестная ошибка. Пожалуйста, обратитесь в поддержку'));
  }).catch((error) => Promise.reject(error));
};
