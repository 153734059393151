import { takeLatest, put } from 'redux-saga/effects';
import axios from 'axios';
import { URL_GET_SBP_PARTICIPANTS } from '../../utils/variables';

function* getParticipantsSaga() {
  try {
    const { data } = yield axios.get(URL_GET_SBP_PARTICIPANTS);
    yield put({
      type: 'GET_PARTICIPANTS_SUCCESS',
      payload: data,
    });
  } catch (error) {
    yield put({
      type: 'GET_PARTICIPANTS_FAIL',
    });
  }
}

function* sbp() {
  yield takeLatest('GET_PARTICIPANTS', getParticipantsSaga);
}

export default sbp;
