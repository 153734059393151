const initialState = {
  id: null,
  loading: true,
};

const setTerminal = (state, payload) => ({
  ...state,
  ...payload,
});

const getTerminal = (state) => ({
  loading: true,
});

// const getTerminalSuccess = (state, payload) => ({
//   ...state,
//   ...payload,
//   loading: false,
// });

const getTerminalSuccess = (state, payload) => ({
  ...state,
  ...payload,
  loading: false,
});

const getTerminalFail = (state, payload) => ({
  ...state,
  error: payload,
  loading: false,
});

const SET_TERMINAL = 'SET_TERMINAL';
const GET_TERMINAL = 'GET_TERMINAL';
const GET_TERMINAL_SUCCESS = 'GET_TERMINAL_SUCCESS';
const GET_TERMINAL_FAIL = 'GET_TERMINAL_FAIL';

function terminal(state = initialState, action) {
  switch (action.type) {
    case SET_TERMINAL: return setTerminal(state, action.payload);
    case GET_TERMINAL: return getTerminal(state);
    case GET_TERMINAL_SUCCESS: return getTerminalSuccess(state, action.payload);
    case GET_TERMINAL_FAIL: return getTerminalFail(state, action.payload);
    default: return state;
  }
}

export default terminal;
