const initialState = {
  loading: false,
  isSubmitting: false,
  step: 1,
  serviceSum: 0,
  commission: 0,
  fixSum: false,
  commission_loading: false,
};

const setTelepayService = (state, payload) => ({
  ...state,
  ...payload,
});

const getTelepayServiceForm = (state, payload) => ({
  ...state, ...payload, loading: true,
});

const getTelepayServiceFormSuccess = (state, payload) => ({
  ...state, form: payload, loading: false,
});

const getTelepayServiceFormFail = (state, payload) => ({
  ...state, error: payload, loading: false,
});

const getTPServiceApproval = (state, payload) => ({
  ...state, approval_loading: true,
});

const getTPServiceApprovalSuccess = (state, payload) => ({
  ...state, ...payload, approval_loading: false,
});

const getTPServiceApprovalFail = (state, payload) => ({
  ...state, error: payload, approval_loading: false,
});

const changeTPServiceSum = (state, payload) => ({
  ...state, commission_loading: true, serviceSum: parseFloat(payload),
});
const changeTPServiceSumSuccess = (state, payload) => ({
  ...state, commission_loading: false, commission: parseFloat(payload),
});
const changeTPServiceSumFail = (state, payload) => ({
  ...state, commission_loading: false, commission: -1, commission_error: payload,
});

const GET_TELEPAY_SERVICE_FORM = 'GET_TELEPAY_SERVICE_FORM';
const GET_TELEPAY_SERVICE_FORM_SUCCESS = 'GET_TELEPAY_SERVICE_FORM_SUCCESS';
const GET_TELEPAY_SERVICE_FORM_FAIL = 'GET_TELEPAY_SERVICE_FORM_FAIL';
const GET_TP_SERVICE_APPROVAL = 'GET_TP_SERVICE_APPROVAL';
const GET_TP_SERVICE_APPROVAL_SUCCESS = 'GET_TP_SERVICE_APPROVAL_SUCCESS';
const GET_TP_SERVICE_APPROVAL_FAIL = 'GET_TP_SERVICE_APPROVAL_FAIL';
const CHANGE_TP_SERVICE_SUM = 'CHANGE_TP_SERVICE_SUM';
const CHANGE_TP_SERVICE_SUM_SUCCESS = 'CHANGE_TP_SERVICE_SUM_SUCCESS';
const CHANGE_TP_SERVICE_SUM_FAIL = 'CHANGE_TP_SERVICE_SUM_FAIL';

const TP_SERVICE_PAY_APPLE_PAY = 'TP_SERVICE_PAY_APPLE_PAY';
const TP_SERVICE_PAY_GOOGLE_PAY = 'TP_SERVICE_PAY_GOOGLE_PAY';
const TP_SERVICE_PAY_SAMSUNG_PAY = 'TP_SERVICE_PAY_SAMSUNG_PAY';
const TP_SERVICE_PAY_CARD = 'TP_SERVICE_PAY_CARD';
const TP_SERVICE_PAY_FAIL = 'TP_SERVICE_PAY_FAIL';

function telepayService(state = initialState, action) {
  switch (action.type) {
    case 'SET_TELEPAY_SERVICE': return setTelepayService(state, action.payload);
    case GET_TELEPAY_SERVICE_FORM: return getTelepayServiceForm(state, action.payload);
    case GET_TELEPAY_SERVICE_FORM_SUCCESS: return getTelepayServiceFormSuccess(state, action.payload);
    case GET_TELEPAY_SERVICE_FORM_FAIL: return getTelepayServiceFormFail(state, action.payload);
    case GET_TP_SERVICE_APPROVAL: return getTPServiceApproval(state, action.payload);
    case GET_TP_SERVICE_APPROVAL_SUCCESS: return getTPServiceApprovalSuccess(state, action.payload);
    case GET_TP_SERVICE_APPROVAL_FAIL: return getTPServiceApprovalFail(state, action.payload);
    case 'SET_TP_SERVICE_STEP': return { ...state, step: action.payload };
    case 'SET_TP_SERVICE_ATTRIBUTE': return { ...state, attribute: action.payload };
    case 'SET_TP_SERVICE_PARAMS': return { ...state, ...action.payload };
    case CHANGE_TP_SERVICE_SUM: return changeTPServiceSum(state, action.payload);
    case CHANGE_TP_SERVICE_SUM_SUCCESS: return changeTPServiceSumSuccess(state, action.payload);
    case CHANGE_TP_SERVICE_SUM_FAIL: return changeTPServiceSumFail(state, action.payload);
    case TP_SERVICE_PAY_APPLE_PAY: return { ...state, isSubmitting: true, payLoading: true };
    case TP_SERVICE_PAY_GOOGLE_PAY: return { ...state, isSubmitting: true, payLoading: true };
    case TP_SERVICE_PAY_SAMSUNG_PAY: return { ...state, isSubmitting: true, payLoading: true };
    case TP_SERVICE_PAY_CARD: return { ...state, isSubmitting: true, payLoading: true };
    case TP_SERVICE_PAY_FAIL: return { ...state, isSubmitting: false, payLoading: false };
    default: return state;
  }
}
export default telepayService;
