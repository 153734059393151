import { getHost } from '../../utils/function';
import agents from '../../utils/agents';

const initialState = {
  ...agents(getHost()),
};

const setAgent = (state, payload) => {
  const temp = state.agent_name !== 'genesis' && state.agent_name !== 'unknown' && payload ? agents(payload) : state;
  return {
    ...temp,
  };
};

function agent(state = initialState, action) {
  switch (action.type) {
    case 'SET_AGENT': return setAgent(state, action.payload);
    default: return state;
  }
}
export default agent;
