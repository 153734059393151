const initialState = {
  loading: false,
};

const getUser = (state) => ({
  ...state,
  loading: true,
});

const getUserSuccess = (state, payload) => ({
  ...state,
  loading: false,
  ...payload,
});
const getUserFail = (state, payload) => ({
  ...state,
  error: payload,
  loading: false,
});

const GET_USER = 'GET_USER';
const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const GET_USER_FAIL = 'GET_USER_FAIL';
function status(state = initialState, action) {
  switch (action.type) {
    case GET_USER: return getUser(state);
    case GET_USER_SUCCESS: return getUserSuccess(state, action.payload);
    case GET_USER_FAIL: return getUserFail(state, action.payload);
    default: return state;
  }
}
export default status;
