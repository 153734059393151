const initialState = {
};

const setCompany = (state, payload) => ({
  ...state,
  ...payload,
});

function company(state = initialState, action) {
  switch (action.type) {
    case 'SET_COMPANY': return setCompany(state, action.payload);
    default: return state;
  }
}
export default company;
