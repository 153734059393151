import { put, select, takeLatest } from 'redux-saga/effects';
import selectors from '../selectors';

function* setSumSaga({ payload }) {
  try {
    const e = payload; const maxSpentBonus = yield select(selectors.getMaxSpentBonus);
    const spentBonuses = yield select(selectors.getSpentBonuses);
    const bonuses = yield select(selectors.getUserBonuses);

    if (Number(e.target.value) < 0) e.target.value = 0;
    const { value } = e.target;

    yield put({
      type: 'SET_SUM_SUCCESS',
      payload: {
        sum: value,
        spentBonuses: Math.min(Math.floor(maxSpentBonus * value), spentBonuses, bonuses),
      },
    });
  } catch (error) {
    yield put({ type: 'SET_SUM_FAIL', payload: error.message });
  }
}

function* amounts() {
  yield takeLatest('SET_SUM', setSumSaga);
}

export default amounts;
