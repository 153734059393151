import {
  call, delay, put, select, takeLatest,
} from 'redux-saga/effects';
import selectors from '../selectors';
import getTerminal from '../../services/api/getTerminal';
import history from '../../history';

function* fetcher(id) {
  for (let i = 0; i < 60; i += 1) {
    const resp = yield call(getTerminal, id);
    if (resp.payment.id) { return resp.payment; }
    yield delay(1000);
  }
  throw new Error('Истекло');
}

function* getTerminalSaga() {
  try {
    const id = yield select(selectors.getLocationId);
    const response = yield call(getTerminal, id);
    if (response.rdStatus) {
      const prefix = yield select(selectors.getLocationPrefix);
      history.push(`${prefix}status/${id.slice(1)}`);
    }
    if (response.terminal.customize && response.terminal.customize.textFields) {
      yield put({ type: 'SET_TEXT_FIELD_SCHEMA', payload: response.terminal.customize.textFields });
    }
    yield put({ type: 'SET_AGENT', payload: response.agent });

    if (response.terminal.defaultprice) {
      yield put({ type: 'SET_AMOUNTS', payload: { sum: response.terminal.defaultprice } });
    }
    if (response.terminal.amount) {
      yield put({ type: 'SET_AMOUNT_FIX_SUM', payload: response.terminal.amount });
      response.terminal.isFlexible = true;
    }
    if (response.payment.id) {
      if (Number(response.payment.status) > 1) {
        const prefix = yield select(selectors.getLocationPrefix);
        history.push(`${prefix}status/${response.payment.id}`);
      }
      yield put({ type: 'SET_TRANSACTION', payload: response.payment });
      yield put({ type: 'SET_AMOUNT_FIX_SUM', payload: response.payment.amount });
    } else {
      yield put({ type: 'SET_TRANSACTION', payload: { id: null } });
    }
    if (Number(response.settings.fiscalization_type) > 0) {
      const address = response.payment ? response.payment.delivery_fcheck : '';
      yield put({ type: 'SET_FISCAL', payload: { support: true, isSend: !!address, address } });
    }

    if (response.settings.is_individual) {
      yield put({ type: 'SET_AGENT', payload: 'tips' });
      response.terminal.min_amount = 10;
    }

    yield put({
      type: 'SET_COMPANY',
      payload: {
        name: response.urData.contname,
        inn: response.urData.inn,
        logo: response.settings.logo,
        id: response.recipient,
      },
    });
    yield put({ type: 'SET_SETTINGS', payload: response.settings });
    if (response.settings.form_id) {
      yield put({ type: 'GET_TELEPAY_SERVICE_FORM', payload: { formId: response.settings.form_id, serviceId: response.settings.service_id } });
      history.push(`/service/${id}`);
    }
    yield put({ type: 'GET_TERMINAL_SUCCESS', payload: response.terminal });
    if (!response.payment.id && !response.terminal.isFlexible) {
      const transaction = yield call(fetcher, id);
      yield put({ type: 'SET_TRANSACTION', payload: transaction });
    }
  } catch (error) {
    yield put({ type: 'GET_TERMINAL_FAIL', payload: error.message });
  }
}

function* terminal() {
  yield takeLatest('GET_TERMINAL', getTerminalSaga);
}

export default terminal;
