const initialState = {
  textFields: [],
  citizen: {
    first_name: '',
    middle_name: '',
    last_name: '',
    doc_number: '',
    doc_issuer: '',
    doc_issuer_date: '',
    doc_exp_date: '',
    citizenship: '',
    contact: '',
  },
  comment: '',
};

const setCustomParams = (state, payload) => ({
  ...state,
  ...payload,
});

const setTextFieldSchema = (state, payload) => {
  const textFields = [];
  Object.values(payload.map((i) => ({ name: i.displayName, required: i.required })))
    .forEach((i) => {
      textFields.push({ ...i, value: '' });
    });
  return {
    ...state, textFields,
  };
};

const setTextField = (state, payload) => ({
  ...state,
  textFields: [
    ...state.textFields.slice(0, payload.index),
    { ...state.textFields[payload.index], value: payload.value },
    ...state.textFields.slice(payload.index + 1)],
});

const setComment = (state, payload) => ({ ...state, comment: payload });

const setCitizen = (state, action) => ({
  ...state,
  citizen: { ...state.citizen, [action.payload.name]: action.payload.value },
});

function customParams(state = initialState, action) {
  switch (action.type) {
    case 'SET_CUSTOM_PARAMS': return setCustomParams(state, action.payload);
    case 'SET_TEXT_FIELD': return setTextField(state, action.payload);
    case 'SET_TEXT_FIELD_SCHEMA': return setTextFieldSchema(state, action.payload);
    case 'SET_COMMENT': return setComment(state, action.payload);
    case 'SET_CITIZEN': return setCitizen(state, action.payload);
    default: return state;
  }
}
export default customParams;
