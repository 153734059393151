const initialState = {
  showCurrency: (new URLSearchParams(window.location.search).get('currency') || '').toUpperCase(),
  sum: 0,
  spentBonuses: 0,
  fix: false,
  error: null,
};

const setAmounts = (state, payload) => ({
  ...state,
  ...payload,
});

const setFixSum = (state, payload) => ({
  ...state,
  sum: parseFloat(payload),
  fix: true,
});

const setSum = (state) => ({ ...state });

const setSumSuccess = (state, payload) => ({ ...state, ...payload });
const setSumFail = (state, payload) => ({ ...state, error: payload });

function amounts(state = initialState, action) {
  switch (action.type) {
    case 'SET_AMOUNTS': return setAmounts(state, action.payload);
    case 'SET_AMOUNT_FIX_SUM': return setFixSum(state, action.payload);
    case 'SET_SUM': return setSum(state, action.payload);
    case 'SET_SUM_SUCCESS': return setSumSuccess(state, action.payload);
    case 'SET_SUM_FAIL': return setSumFail(state, action.payload);
    default: return state;
  }
}
export default amounts;
