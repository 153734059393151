import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: require('./languages/en'),
  ru: require('./languages/ru'),
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: navigator.language,
    fallbackLng: 'en',
    keySeparator: '.',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
