const initialState = {
  loading: false,
};

const getStatus = (state) => ({
  loading: true,
});

const getStatusSuccess = (state, payload) => ({
  ...state,
  status: Number(payload),
  loading: false,
});
const getStatusFail = (state, payload) => ({
  ...state,
  error: payload,
  loading: false,
  status: 6,
});

const GET_STATUS = 'GET_STATUS';
const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
const GET_STATUS_FAIL = 'GET_STATUS_FAIL';
const FETCH_STATUS = 'FETCH_STATUS';

function status(state = initialState, action) {
  switch (action.type) {
    case GET_STATUS: return getStatus(state);
    case FETCH_STATUS: return getStatus(state);
    case GET_STATUS_SUCCESS: return getStatusSuccess(state, action.payload);
    case GET_STATUS_FAIL: return getStatusFail(state, action.payload);
    default: return state;
  }
}
export default status;
