import axios from 'axios';
import { URL_GET_PROVIDER_COMMISSION } from '../utils/variables';

const getProviderCommission = (recipient, service, terminal, summ) => axios
  .post(URL_GET_PROVIDER_COMMISSION, {
    recipient, service_id: service, terminal_id: terminal, summ,
  })
  .then(({ data }) => {
    if (data.status === 0) {
      const { commission } = data.response;
      return parseFloat(commission);
    }
    throw new Error('Не удалось получить комиссию');
  });
export default getProviderCommission;
