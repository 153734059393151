import React from 'react';

function ToastError(payError) {
  return (
    <div className="text-danger border-top border-bottom border-danger font-10 text-center p-1">
      {payError}
    </div>
  );
}
export default ToastError;
