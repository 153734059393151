const initialState = {

};

const setSettings = (state, payload) => ({
  ...state,
  ...payload,
});

function settings(state = initialState, action) {
  switch (action.type) {
    case 'SET_SETTINGS': return setSettings(state, action.payload);
    default: return state;
  }
}
export default settings;
