import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import selectors from '../selectors';
import getTelepayForm from '../../services/api/getTelepayForm';
import { getApproval } from '../../services/api/telepayServices';
import getProviderCommission from '../../services/getProviderCommission';
import ToastError from '../../components/UI/ToastError';
import i18n from '../../i18n';
import { URL_CUSTOMERS_PAY_TP_SERVICE } from '../../utils/variables';

function* changeAmountSaga({ payload }) {
  try {
    const { fixSum, serviceId } = yield select(selectors.getTPService);
    yield put({ type: 'SET_TP_SERVICE_PARAMS', payload: { fixSum: false } });
    if (fixSum) { yield put({ type: 'SET_TP_SERVICE_PARAMS', payload: { fixSum: false } }); }
    const recipient = yield select(selectors.getRecipient);
    const terminal = yield select(selectors.getTerminal);
    const commission = yield call(getProviderCommission, recipient, serviceId, terminal.id, payload);
    yield put({ type: 'CHANGE_TP_SERVICE_SUM_SUCCESS', payload: commission });
    yield put({ type: 'SET_AMOUNTS', payload: { sum: parseFloat(payload) + commission } });
  } catch (error) {
    yield put({ type: 'CHANGE_TP_SERVICE_SUM_FAIL' });
  }
}

function* getApprovalSaga({ payload }) {
  const { values, actions } = payload;
  try {
    const recipient = yield select(selectors.getRecipient);
    const terminal = yield select(selectors.getTerminal);
    const { formId, fixSum } = yield select(selectors.getTPService);
    if (values.sum) {
      yield put({ type: 'CHANGE_TP_SERVICE_SUM', payload: values.sum });
    }
    const response = yield call(getApproval, values, recipient, terminal.id, formId);
    if (response.amount) {
      yield put({ type: 'SET_AMOUNTS', payload: { fix: false } });
      yield put({ type: 'CHANGE_TP_SERVICE_SUM', payload: response.amount });
    }
    actions.setValues(response.values);
    yield put({ type: 'SET_TP_SERVICE_STEP', payload: 2 });
    actions.setSubmitting(true);
    yield put({
      type: 'GET_TP_SERVICE_APPROVAL_SUCCESS',
      payload: {
        attribute: response.attribute, serviceForm: response.serviceForm,
      },
    });
  } catch (error) {
    toast(ToastError(error.message));
    actions.setSubmitting(false);
    yield put({ type: 'GET_TP_SERVICE_APPROVAL_SUCCESS', error: error.message });
  }
}

function* getTelepayServiceSaga({ payload }) {
  try {
    const response = yield call(getTelepayForm, payload.formId);
    yield put({ type: 'GET_TELEPAY_SERVICE_FORM_SUCCESS', payload: response.fields });
  } catch (error) {
    yield put({ type: 'GET_TELEPAY_SERVICE_FORM_FAIL', payload: error.message });
  }
}

function* performPayment(payload) {
  const tpService = yield select(selectors.getTPService);
  const { serviceForm, commission, serviceSum } = tpService;
  const sum = yield select(selectors.getSum);
  const temp_attr = [];
  for (const key in serviceForm) {
    if (key !== 'id1') { temp_attr.push({ name: key, value: serviceForm[key] }); }
  }
  if (serviceSum < 10) {
    throw new Error(`${i18n.t('The amount of payment must be at least')} 10 ₽`);
  }
  if (commission < 0) { throw new Error('Некорректное значение комиссии'); }
  if (tpService.commission_loading) { throw new Error('Подождите пока закончится расчет комиссии и повторите снова'); }
  if (commission + serviceSum !== sum) { throw new Error('Проверьте коммиссию и повторите оплату'); }
  return {
    sum: serviceSum,
    sum_in: sum,
    account: serviceForm.id1,
    attributes: [...tpService.attribute.filter((i) => i.name !== '#sum'), ...temp_attr],
    service_id: tpService.serviceId,
  };
}

function* paymentApplePaySaga({ payload }) {
  try {
    const params = yield call(performPayment, payload);
    yield put({ type: 'PAYMENT_APPLE_PAY', payload: { args: { ...payload, custom_params: params, source: 'telepay_service' }, url: URL_CUSTOMERS_PAY_TP_SERVICE } });
  } catch (error) {
    toast(ToastError(error.message));
    yield put({ type: 'TP_SERVICE_PAY_FAIL' });
  }
}
function* paymentGooglePaySaga({ payload }) {
  try {
    const params = yield call(performPayment, payload);
    yield put({ type: 'PAYMENT_GOOGLE_PAY', payload: { args: { ...payload, custom_params: params, source: 'telepay_service' }, url: URL_CUSTOMERS_PAY_TP_SERVICE } });
  } catch (error) {
    toast(ToastError(error.message));
    yield put({ type: 'TP_SERVICE_PAY_FAIL' });
  }
}
function* paymentSamsungPaySaga({ payload }) {
  try {
    const params = yield call(performPayment, payload);
    yield put({ type: 'PAYMENT_SAMSUNG_PAY', payload: { args: { ...payload, custom_params: params, source: 'telepay_service' } } });
  } catch (error) {
    toast(ToastError(error.message));
    yield put({ type: 'TP_SERVICE_PAY_FAIL' });
  }
}
function* paymentCardSaga({ payload }) {
  try {
    const params = yield call(performPayment, payload);
    yield put({ type: 'PAYMENT_CARD', payload: { args: { ...payload, custom_params: params, source: 'telepay_service' }, url: URL_CUSTOMERS_PAY_TP_SERVICE } });
  } catch (error) {
    toast(ToastError(error.message));
    yield put({ type: 'TP_SERVICE_PAY_FAIL' });
  }
}

function* paymentMonetaSaga({ payload }) {
  try {
    const params = yield call(performPayment, payload);
    yield put({ type: 'PAYMENT_MONETA', payload: { args: { ...payload, custom_params: params, source: 'telepay_service' } } });
  } catch (error) {
    toast(ToastError(error.message));
    yield put({ type: 'TP_SERVICE_PAY_FAIL' });
  }
}

function* telepayService() {
  yield takeLatest('GET_TELEPAY_SERVICE_FORM', getTelepayServiceSaga);
  yield takeLatest('GET_TP_SERVICE_APPROVAL', getApprovalSaga);
  yield takeLatest('CHANGE_TP_SERVICE_SUM', changeAmountSaga);
  yield takeLatest('TP_SERVICE_PAY_APPLE_PAY', paymentApplePaySaga);
  yield takeLatest('TP_SERVICE_PAY_GOOGLE_PAY', paymentGooglePaySaga);
  yield takeLatest('TP_SERVICE_PAY_SAMSUNG_PAY', paymentSamsungPaySaga);
  yield takeLatest('TP_SERVICE_PAY_CARD', paymentCardSaga);
  yield takeLatest('TP_SERVICE_PAY_MONETA', paymentMonetaSaga);
}

export default telepayService;
