import { getColorThemeFromURL, isIFrame } from '../../utils/function';

const initialState = {
  id: null,
  // TODO chagne in useEffect FRAME
  isFrame: window.location.href.indexOf('frame') !== -1,
  iframeMode: isIFrame(),
  // whitelabel: true,
  isPreview: window.location.href.indexOf('fde242c197e749eedcaa25eea6d2d4fb') !== -1,
  customTheme: { ...getColorThemeFromURL() },
  prefix: '/',
};

function location(state = initialState, action) {
  switch (action.type) {
    case 'SET_ID': return {
      ...state,
      id: action.payload,
    };
    case 'SET_ADDITIONAL_LOCATION': return {
      ...state, ...action.payload,
    };
    case 'SET_LOCATION_PREFIX': return { ...state, prefix: action.payload };
    // case 'SET_WHITELABEL': return {
    //   ...state, whitelabel: action.payload,
    // };
    default: return state;
  }
}
export default location;
