import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';
import { getCookie } from '../../utils/function';
import getUser from '../../services/api/getUser';
import selectors from '../selectors';
import { URL_GET_CUSTOMER_SESSION } from '../../utils/variables';

function* getUserSaga() {
  try {
    // TODO get bonuses
    const { data } = yield axios.post(URL_GET_CUSTOMER_SESSION, {}, {
      withCredentials: true,
    });
    const id = data.session_key;
    yield put({
      type: 'GET_USER_SUCCESS',
      payload: {
        bonuses: 0,
        cook: id,
      },
    });

    //   const cook = yield select(selectors.getUserId);
  //
  //   const id = cook || getCookie('id');
  //   const response = yield call(getUser, id);
  //   let { deliveryFisCheck } = response;
  //   if (deliveryFisCheck === null
  //       || deliveryFisCheck.type === null
  //       || deliveryFisCheck.type === 'null') deliveryFisCheck = { type: null };
  //   yield put({
  //     type: 'SET_FISCAL',
  //     payload: {
  //       isSend: deliveryFisCheck && (deliveryFisCheck.type === 'tel' || deliveryFisCheck.type === 'email'),
  //       address: deliveryFisCheck && deliveryFisCheck.address ? deliveryFisCheck.address : '',
  //     },
  //   });
  //   yield put({ type: 'GET_USER_SUCCESS', payload: response });
  // //  TODO
  // //    useEffect(() => {
  //   //     dispatch(setAddress(userInfo.address));
  //   //     dispatch(setIsSendCheck(userInfo && userInfo.address));
  //   //   }, [userInfo]);
  } catch (error) {
    yield put({ type: 'GET_USER_FAIL', payload: error.message });
  }
}

function* user() {
  yield takeLatest('GET_USER', getUserSaga);
}

export default user;
