import axios from 'axios';
import i18n from '../../i18n';

const { URL_PAY_STATUS } = require('../../utils/variables');

const getStatus = (id) => {
  if (id !== undefined) {
    return axios.post(URL_PAY_STATUS, { id }).then((result) => {
      if (result.data.status !== 1) {
        return Promise.resolve(result.data);
      }
      return Promise.reject(new Error(i18n.t('Payment not found')));
    }).catch((error) => Promise.reject(error));
  }
  return Promise.reject(new Error(i18n.t('Payment not found')));
};

export default getStatus;
