export default {
  getLocationId: (state) => state.location.id,
  getUserId: (state) => state.user.cook,
  getMaxSpentBonus: (state) => state.settings.max_spend_bonus,
  getSum: (state) => state.amounts.sum,
  getUserBonuses: (state) => state.user.bonuses,
  getSpentBonuses: (state) => state.amounts.spentBonuses,
  getTerminal: (state) => state.terminal,
  getFiscal: (state) => state.fiscal,
  getTransaction: (state) => state.transaction,
  getCustomParams: (state) => state.customParams,
  getSettings: (state) => state.settings,
  getRecipient: (state) => state.company.id,
  getLocationPrefix: (state) => state.location.prefix,
  getTPService: (state) => state.telepayService,
  getCustomerCommission: (state) => state.tips.customerCommission,
  getTipsAmount: (state) => state.tips.amount,
};
