const initialState = {
  id: null,
};

const setTranscation = (state, payload) => ({
  ...state,
  ...payload,
  bonus_gift: Number(payload.bonus_gift),
  amountbonus: Number(payload.amountbonus),
});

const setOrderId = (state, payload) => ({
  ...state, orderId: payload,
});
function transaction(state = initialState, action) {
  switch (action.type) {
    case 'SET_TRANSACTION': return setTranscation(state, action.payload);
    case 'SET_ORDER_ID': return setOrderId(state, action.payload);
    default: return state;
  }
}
export default transaction;
