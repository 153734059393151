import React from 'react';

const Preloader = (props) => (
  <div className="loader-container">
    <div className="lds-default">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

Preloader.propTypes = {};

Preloader.defaultProps = {};

export default Preloader;
