import {
  call, delay, put, select, takeLatest,
} from 'redux-saga/effects';
import getStatus from '../../services/api/getStatus';
import selectors from '../selectors';
import history from '../../history';
import i18n from '../../i18n';

function* fetcher(id) {
  for (let i = 0; i < 100; i += 1) {
    const resp = yield call(getStatus, id);
    if (resp.transaction.statusNum !== 0 && resp.transaction.statusNum !== 1) {
      return resp.transaction;
    }
    yield delay(i < 60 ? 1000 : 10000);
  }
  throw new Error(i18n.t('The waiting time has expired'));
}

function* fetchStatusSaga({ payload }) {
  let prefix = yield select(selectors.getLocationPrefix);
  try {
    const id = payload;
    const response = yield call(getStatus, id);
    const transaction = yield select(selectors.getTransaction);
    const { order, params } = transaction;
    const { success_url: successUrl, fail_url: failUrl } = order || params || {};
    // yield put({ type: 'SET_AGENT', payload: response.company.agent });
    yield put({ type: 'SET_COMPANY', payload: response.company });
    yield put({ type: 'SET_TRANSACTION', payload: response.transaction });
    if (response.transaction && (response.transaction.statusNum === 0 || response.transaction.statusNum === 1)) {
      const resp = yield call(fetcher, id);
      yield put({ type: 'SET_TRANSACTION', payload: resp });
      yield put({ type: 'GET_STATUS_SUCCESS', payload: resp.statusNum });
      if (successUrl && resp.statusNum === 2) {
        window.location = successUrl;
      } else if (failUrl && resp.statusNum === 6) {
        window.location = failUrl;
      } else {
        if (resp.paySys && resp.paySys.bank_details && resp.paySys.bank_details.agent === 'TelepayService') {
          prefix = '/service/';
        }
        history.push(`${prefix}status/${payload}`);
      }
    }
    yield put({ type: 'GET_TERMINAL_SUCCESS', payload: { id: response.transaction.terid, ...response.terminal } });
    if (response.transaction && response.transaction.statusNum === 2) {
      yield put({ type: 'GET_STATUS_SUCCESS', payload: Number(response.transaction.statusNum) });
      if (successUrl) {
        window.location = successUrl;
      } else {
        history.push(`${prefix}status/${payload}`);
      }
    }
    if (response.transaction && response.transaction.statusNum === 4) {
      yield put({ type: 'GET_STATUS_SUCCESS', payload: Number(response.transaction.statusNum) });
      if (failUrl) {
        window.location = failUrl;
      } else {
        history.push(`${prefix}status/${payload}`);
      }
    }
    if (response.transaction && (response.transaction.statusNum === 6 || response.transaction.statusNum === 5)) {
      yield put({ type: 'GET_STATUS_FAIL', payload: response.transaction.statusMsg });
      if (failUrl) {
        window.location = failUrl;
      } else {
        history.push(`${prefix}status/${payload}`);
      }
    }
  } catch (error) {
    yield put({ type: 'GET_STATUS_FAIL', payload: error.message });
    history.push(`${prefix}status/${payload}`);
  }
}

function* getStatusSaga() {
  try {
    const id = yield select(selectors.getLocationId);
    const response = yield call(getStatus, id);
    yield put({ type: 'SET_AGENT', payload: response.company.agent });
    yield put({ type: 'SET_COMPANY', payload: response.company });
    yield put({ type: 'SET_TRANSACTION', payload: response.transaction });
    if (response.transaction && response.transaction.statusNum === 0) {
      const resp = yield call(fetcher, id);
      yield put({ type: 'SET_TRANSACTION', payload: resp });
      yield put({ type: 'GET_STATUS_SUCCESS', payload: resp.statusNum });
    }
    yield put({ type: 'GET_TERMINAL_SUCCESS', payload: { id: response.transaction.terid, ...response.terminal } });

    if (response.transaction && (response.transaction.statusNum === 2 || response.transaction.statusNum === 4)) {
      yield put({ type: 'GET_STATUS_SUCCESS', payload: Number(response.transaction.statusNum) });
    }
    if (response.transaction && (response.transaction.statusNum === 6 || response.transaction.statusNum === 5)) {
      yield put({ type: 'GET_STATUS_FAIL', payload: response.transaction.statusMsg });
    }
    if (response.transaction.paySys
      && response.transaction.paySys.bank_details
      && response.transaction.paySys.bank_details.agent === 'TelepayService') {
      history.push(`/service/status/${response.transaction.id}`);
    }
  } catch (error) {
    yield put({ type: 'GET_STATUS_FAIL', payload: error.message });
  }
}

function* status() {
  yield takeLatest('GET_STATUS', getStatusSaga);
  yield takeLatest('FETCH_STATUS', fetchStatusSaga);
}

export default status;
