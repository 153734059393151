const initialState = {
  customerCommission: true,
  amount: 0,
  commission: 0,
};

const setTipsAmount = (state, payload) => ({
  ...state, amount: payload,
});

const setCustomerCommission = (state, payload) => ({
  ...state, customerCommission: payload,
});

const SET_TIPS_AMOUNT_SUCCESS = 'SET_TIPS_AMOUNT_SUCCESS';
const SET_CUSTOMER_COMMISSION = 'SET_CUSTOMER_COMMISSION';

function tips(state = initialState, action) {
  switch (action.type) {
    case SET_TIPS_AMOUNT_SUCCESS:
      return setTipsAmount(state, action.payload);
    case SET_CUSTOMER_COMMISSION:
      return setCustomerCommission(state, action.payload);
    default:
      return state;
  }
}

export default tips;
