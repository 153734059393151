const agents = (host) => {
  switch (host) {
    case 'm-pay.telepayonline.ru':
    case 'https://m-pay.telepayonline.ru':
    case '176452ad1b6299580540df7fd4edbe6e': { return {
      agent_name: 'telepay',
      pathImgs: '/img/whitelabels/telepay',
      name: 'Telepay',
      logo_url: '/img/whitelabels/telepay/acquiring-logo.svg',
      pay_url: 'https://m-pay.telepayonline.ru',
      loyalty_url: '',
      main_url: 'https://telepayonline.ru/',
      // rb_link: 'http://nkopremium.ru',
      // rb_name: 'НКО "Премиум" (ООО)',
      whitelabel: false,
      bonus_program: false,
      // support_phone: '+7 (495) 660-94-14',
      // support_email: 'support@telepayural.ru',
      // support_telegram: '',
      // support_telegram: 'invoice_support',
      service_support_phone: '8(800)7000-519',
      service_support_email: 'support@telepay.ru',
      showPowered: true,
      showUrData: true,
    }; }
    case '2ea8ddb1e39d971697576027c20dfa9d': { return {
      agent_name: 'mts',
      pathImgs: '/img/invoice',
      name: 'МТС Касса',
      logo_url: '/img/mts/logo.svg',
      pay_url: 'https://pay.invoice.su/mts',
      loyalty_url: '',
      main_url: 'https://kassa.mts.ru',
      whitelabel: false,
      bonus_program: true,
      support_phone: '+7 (495) 266-69-19',
      support_email: 'support@invoice.su',
      support_telegram: 'invoice_support',
      showPowered: true,
      redirect_path: '/mts',
      showUrData: true,
    }; }
    case 'app.ecomkassa.ru':
    case 'https://app.ecomkassa.ru':
    case '235f4fe55c244b9f87eb0eb98955aecf': { return {
      agent_name: 'ecomkassa',
      pathImgs: '/img/whitelabels/ecomkassa',
      name: 'EcomKassa',
      logo_url: '/img/whitelabels/ecomkassa/logo.png',
      // TODO
      pay_url: 'https://app.ecomkassa.ru/acquiring/ecom',
      main_url: 'https://ecomkassa.ru/',
      loyalty_url: '',
      whitelabel: true,
      bonus_program: false,
      support_phone: '+7 (800) 100-23-30',
      support_email: 'help@ecomkassa.ru',
      support_telegram: 'Ecomkassa_help_bot',
      showPowered: true,
      showUrData: true,
    }; }
    case 'localhost:3000':
    case 'invoice':
    case 'pay.invoice.su':
    case 'localhost:3001':
    case 'local.invoice.su:3000':
    case 'paytest.invoice.su':
    case 'pay.invoiced.ml':
    case 'https://pay.invoice.su': { return {
      agent_name: 'invoice',
      pathImgs: '/img/invoice',
      name: 'Invoice',
      logo_url: '/img/logo.svg',
      pay_url: 'https://pay.invoice.su',
      loyalty_url: 'https://st.invoice.su/docs/personal_data_policy.pdf',
      main_url: 'https://invoice.ru/',
      whitelabel: false,
      bonus_program: true,
      support_phone: '+7 (495) 266-69-19',
      support_email: 'support@invoice.su',
      support_telegram: 'invoice_support',
      showPowered: false,
      showUrData: true,
    }; }
    case 'tips': { return {
      agent_name: 'tips',
      pathImgs: '/img/tips',
      name: 'без купюр',
      logo_url: '/img/tips/logo.svg',
      // TODO
      pay_url: 'https://pay.bt.tips/',
      main_url: 'https://bk.tips/',
      loyalty_url: 'todo',
      whitelabel: false,
      bonus_program: false,
      support_phone: '+7 (495) 266-69-19',
      support_email: 'mail@bk.tips',
      support_telegram: 'invoice_support',
      // redirect_path: '/tips',
      showPowered: true,
      showUrData: true,
    }; }
    case 'pay.genesispay.ru': { return {
      agent_name: 'genesis',
      pathImgs: 'img/genesis/',
      name: 'Payment',
      logo_url: '',
      pay_url: 'https://pay.genesispay.ru',
      loyalty_url: '',
      main_url: window.location.href,
      whitelabel: true,
      bonus_program: false,
      support_phone: '',
      support_email: '',
      support_telegram: '',
      showPowered: false,
      showUrData: false,
    }; }
    default: { return {
      agent_name: 'unknown',
      pathImgs: '/img/',
      name: 'Payment',
      logo_url: '',
      pay_url: 'https://',
      loyalty_url: '',
      main_url: window.location.href,
      whitelabel: true,
      bonus_program: false,
      support_phone: false,
      support_email: false,
      support_telegram: false,
      showPowered: false,
      showUrData: false,

      // pathImgs: '/img/whitelabels/telepay',
      // name: 'Telepay',
      // logo_url: '/img/whitelabels/telepay/acquiring-logo.svg',
      // pay_url: 'https://m-pay.telepayonline.ru',
      // loyalty_url: '',
      // main_url: 'https://telepayonline.ru/',
      // whitelabel: true,
      // bonus_program: false,
      // support_phone: '+7 (495) 660-94-14',
      // support_email: 'support@telepayural.ru',
      // support_telegram: '',
    }; }
  }
};
export default agents;
