import axios from 'axios';
import i18n from '../../i18n';
import { checkAddress, checkRequired, userDevice } from '../../utils/function';
import { URL_CUSTOMERS_PAY } from '../../utils/variables';

const pay = (params) => {
  const {
    terminal, fiscal, transaction, customParams, settings, recipient,
    sum, spentBonuses, cook, args = {}, url,
  } = params;
  if (sum > 0 && (!terminal.min_amount || sum >= terminal.min_amount)
      && (!transaction.min_amount || sum >= transaction.min_amount)) {
    if (fiscal.support && fiscal.isSend) {
      if (fiscal.address === '') {
        return Promise.reject(new Error(i18n.t('You did not enter the address to receive the check')));
      }
      const checkedAddress = checkAddress(fiscal.address);
      if (checkedAddress !== false) {
        args.addressDelivery = checkedAddress;
      } else {
        return Promise.reject(new Error(i18n.t('The entered value is not an email address or phone number')));
      }
    }
    if (sum >= 15000
            && (settings.paySys && settings.paySys.accountant_banks && settings.paySys.accountant_banks.indexOf('NKOPremium') !== -1)
            && args.card && args.card.type === 'card' && !args.card.cardholder) {
      return Promise.reject(new Error(i18n.t('Please enter the name of the cardholder, when paying over 15 000 ₽')));
    }

    if (sum > 1000000) {
      return Promise.reject(new Error(`${i18n.t('The payment amount must not exceed')} 1 000 000 ₽`));
    }

    if (!checkRequired(customParams.textFields)) {
      return Promise.reject(new Error(i18n.t('All required fields must be filled')));
    }
    if ((recipient === 'e340496724ec587cbdc9e257089e6617' || recipient === '90098ea56f6deadb2b3a65294f2bd80f' || recipient === '78') && sum >= 100000) {
      const { citizen } = customParams;
      const validCitizen = citizen.first_name && citizen.last_name && citizen.doc_number
                && citizen.doc_issuer && citizen.doc_exp_date && citizen.doc_issuer_date
                && citizen.citizenship && citizen.contact;
      if (!validCitizen) {
        return Promise.reject(new Error(i18n.t('To make a payment equal to or exceeding 100,000 rubles')));
      }
    }
    return axios.post(url || URL_CUSTOMERS_PAY, {
      tran_id: transaction.id,
      term_id: terminal.id,
      amount: sum,
      amount_bonus: spentBonuses * (-1),
      recipient,
      // isFlexible,
      comment: customParams.comment,
      id: cook,
      order_id: transaction.orderId && !transaction.id ? transaction.orderId : undefined,
      dev_name: userDevice.dev_name,
      browser: userDevice.browser,
      xscreen: userDevice.xscreen,
      yscreen: userDevice.yscreen,
      citizen: customParams.citizen,
      custom_params: {
        textFields: customParams.textFields
          .filter((i) => i.value)
          .map((i) => ({ ...i, required: undefined })),
      },
      ...args,
    }, {
      timeout: 60000,
      // withCredentials: true,
    }).then(({ data }) => data)
      .catch((error) => Promise.reject(new Error(error.response && error.response.data && error.response.data.error && (error.response.data.error.message || 'Internal error'))));
  }
  return Promise.reject(new Error(`${i18n.t('The amount of payment must be at least')} ${transaction.min_amount || terminal.min_amount || 0} ₽`));
};

export default pay;
