import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import selectors from '../selectors';
import pay from '../../services/api/pay';
import { URL_CUSTOMERS_PAY_MONETA, URL_CUSTOMERS_PAY_SAMSUNG, URL_CUSTOMERS_PAY_SBP } from '../../utils/variables';
import history from '../../history';
import SamsungPay from '../../components/PaymentComponents/SamsungPay/SamsungPay';
import ToastError from '../../components/UI/ToastError';

function* getPaymentParams(payload) {
  const terminal = yield select(selectors.getTerminal);
  const fiscal = yield select(selectors.getFiscal);
  const transaction = yield select(selectors.getTransaction);
  const customParams = yield select(selectors.getCustomParams);
  const settings = yield select(selectors.getSettings);
  const recipient = yield select(selectors.getRecipient);
  const sum = yield select(selectors.getSum);
  const spentBonuses = yield select(selectors.getSpentBonuses);
  const cook = yield select(selectors.getUserId);
  return {
    terminal,
    fiscal,
    transaction,
    customParams,
    settings,
    recipient,
    sum,
    spentBonuses,
    cook,
    ...payload,
    url: payload.url,
  };
}

function* performWalletPay(payload) {
  const params = yield call(getPaymentParams, payload);
  let response = yield call(pay, params);
  if (response.status_code === 0) {
    params.transaction.id = response.transaction_id;
    response = yield call(pay, params);
  }
  yield put({ type: 'FETCH_STATUS', payload: response.transaction_id });
  return response;
}

function* paymentApplePaySaga({ payload }) {
  const { resolve, reject, session } = payload.functions;
  const PaymentStatus = { SUCCESS: 0, FAILURE: 1, CANCEL: 2 };
  const prefix = yield select(selectors.getLocationPrefix);
  try {
    const response = yield performWalletPay(payload);
    if (response.status_code === 2) {
      session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
      resolve({ status: PaymentStatus.SUCCESS, transaction_id: response.transaction_id });
      yield put({
        type: 'PAYMENT_APPLE_PAY_SUCCESS',
        payload: {
          transactionId: response,
        },
      });
    } else {
      throw new Error(response.description || response.status_text);
    }
  } catch (error) {
    if (error.message) {
      toast(ToastError(error.message));
    }
    session.abort();
    reject(error.message);
    const transaction = yield select(selectors.getTransaction);
    history.push(`${prefix}status/${transaction.id}`);
    yield put({ type: 'PAYMENT_APPLE_PAY_FAIL', payload: error.message });
  }
}

function* paymentGooglePaySaga({ payload }) {
  try {
    const response = yield performWalletPay(payload);
    if (response.status_code === 2) {
      yield put({
        type: 'PAYMENT_GOOGLE_PAY_SUCCESS',
        payload: {
          transactionId: response,
        },
      });
    } else {
      throw new Error(response.description || response.status_text);
    }
  } catch (error) {
    toast(ToastError(error.message));
    yield put({ type: 'PAYMENT_GOOGLE_PAY_FAIL', payload: error.message });
  }
}

function* paymentSamsungPaySaga({ payload }) {
  try {
    const prefix = yield select(selectors.getLocationPrefix);
    const params = yield call(getPaymentParams, { ...payload, args: { ...payload.args, statusPrefix: `https://${window.location.host}${prefix}` } });
    const response = yield call(pay, { ...params, url: payload.url || URL_CUSTOMERS_PAY_SAMSUNG });
    if (response.resultCode === '0') {
      SamsungPay.connect(response.id, response.href, response.serviceId, response.callback, payload.args.cancelUrl, 'ru', response.encInfo.mod,
        response.encInfo.exp, response.encInfo.keyId);
    }
    yield put({
      type: 'PAYMENT_SAMSUNG_PAY_SUCCESS',
      payload: {
        transactionId: response.tranId,
      },
    });
  } catch (error) {
    toast(ToastError(error.message));
    yield put({ type: 'PAYMENT_SAMSUNG_PAY_FAIL', payload: error.message });
  }
}

function* paymentSBPSaga({ payload }) {
  try {
    const params = yield call(getPaymentParams, payload);
    const response = yield call(pay, { ...params, url: URL_CUSTOMERS_PAY_SBP });
    const { type } = payload;
    const prefix = yield select(selectors.getLocationPrefix);
    if (response.error && response.error.message) { throw new Error(response.error.message); }
    if (!response.url_nspk) { throw new Error('Не удалось оплатить. Если ошибка повторяется, обратитесь в поддержку'); }
    yield put({ type: 'FETCH_STATUS', payload: response.tranId });
    yield put({
      type: 'PAYMENT_SBP_SUCCESS',
      payload: {
        data: {
          urlNspk: window.atob(response.url_nspk),
          urlQr: response.url_qr,
        },
        transactionId: response.tranId,
        openSBPModal: true,
      },
    });
  } catch (error) {
    toast(ToastError(error.message));
    yield put({ type: 'PAYMENT_SBP_FAIL', payload: error.message });
  }
}

function* paymentCardSaga({ payload }) {
  try {
    const params = yield call(getPaymentParams, payload);
    const response = yield call(pay, params);
    switch (response.status_code) {
      case 1: {
        switch (response.tds_version) {
          case '1': {
            yield put({
              type: 'PAYMENT_CARD_SUCCESS',
              payload: {
                version: '1',
                data: {
                  acsUrl: response.redirect,
                  PaReq: response.pareq,
                  MD: response.md,
                  TermUrl: response.term_url,
                },
                transactionId: response.transaction_id,
              },
            });
            break;
          }
          case '2.0': {
            yield put({
              type: 'PAYMENT_CARD_SUCCESS',
              payload: {
                version: '2.0',
                data: {
                  acsUrl: response.acsUrl,
                  ThreeDsMethodData: response.ThreeDsMethodData,
                  ThreeDSMethodNotificationURL: response.ThreeDSMethodNotificationURL,
                  TermUrl: response.TermUrl,
                },
                transactionId: response.transaction_id,
              },
            });
            break;
          }
          default: {
            throw new Error('Invalid 3DS Version');
          }
        }
        break;
      }
      case 6: {
        throw new Error(response.description || response.status_text);
      }
      default: { }
    }
    if (response.error) {
      throw new Error(response.error.message);
    }
    if (response.transaction_id) {
      yield put({ type: 'FETCH_STATUS', payload: response.transaction_id });
    }
  } catch (error) {
    toast(ToastError(error.message));
    yield put({ type: 'PAYMENT_CARD_FAIL', payload: error.message });
  }
}

function* paymentMonetaSaga({ payload }) {
  try {
    const prefix = yield select(selectors.getLocationPrefix);
    const params = yield call(getPaymentParams, { ...payload, args: { ...payload.args } });
    const response = yield call(pay, { ...params, url: payload.url || URL_CUSTOMERS_PAY_MONETA });
    if (response.error) {
      throw new Error(response.error);
    }
    yield put({
      type: 'PAYMENT_MONETA_SUCCESS',
      payload: {
        link: response.url,
        transactionId: response.tranId,
        type: response.type,
        method: response.method,
      },
    });
    yield put({ type: 'FETCH_STATUS', payload: response.tranId });
  } catch (error) {
    toast(ToastError(error.message));
    yield put({ type: 'PAYMENT_MONETA_FAIL', payload: error.message });
  }
}

function* payment() {
  yield takeLatest('PAYMENT_CARD', paymentCardSaga);
  yield takeLatest('PAYMENT_MONETA', paymentMonetaSaga);
  yield takeLatest('PAYMENT_SBP', paymentSBPSaga);
  yield takeLatest('PAYMENT_SAMSUNG_PAY', paymentSamsungPaySaga);
  yield takeLatest('PAYMENT_APPLE_PAY', paymentApplePaySaga);
  yield takeLatest('PAYMENT_GOOGLE_PAY', paymentGooglePaySaga);
}

export default payment;
