const initialState = {
  support: false,
  isSend: false,
  address: '',
};

const setFiscal = (state, payload) => ({
  ...state,
  ...payload,
});

function ficsal(state = initialState, action) {
  switch (action.type) {
    case 'SET_FISCAL': return setFiscal(state, action.payload);
    default: return state;
  }
}
export default ficsal;
