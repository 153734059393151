import { combineReducers } from 'redux';
import agent from './agent';
import amounts from './amounts';
import company from './company';
import customParams from './customParams';
import fiscal from './fiscal';
import location from './location';
import payment from './payment';
import settings from './settings';
import status from './status';
import telepayService from './telepayService';
import terminal from './terminal';
import transaction from './transaction';
import user from './user';
import sbp from './sbp';
import tips from './tips';

const rootReducer = combineReducers({
  agent,
  amounts,
  company,
  customParams,
  fiscal,
  location,
  payment,
  settings,
  status,
  telepayService,
  terminal,
  transaction,
  user,
  sbp,
  tips,
});

export default rootReducer;
