const initialState = {
  loading: false,
  isSubmitting: false,
  openSBPModal: false,
  extra: {},
};

const paymentCard = (state) => ({
  loading: true,
  isSubmitting: true,
});
const paymentSBP = (state) => ({
  loading: true,
  isSubmitting: true,
});
const paymentSBPSuccess = (state, payload) => ({
  type: 'sbp',
  ...payload,
  loading: false,
});
const paymentSBPFail = (state, payload) => ({
  ...payload,
  loading: false,
  isSubmitting: false,
});

const paymentSamsungPay = (state) => ({
  loading: true,
  isSubmitting: true,
});
const paymentSamsungPaySuccess = (state, payload) => ({
  type: 'samsung',
  ...payload,
  loading: false,
});
const paymentSamsungPayFail = (state, payload) => ({
  ...payload,
  loading: false,
  isSubmitting: false,
});

const clearPayment = (state) => initialState;

const toggleOpenSBPModal = (state, payload) => ({
  ...state,
  openSBPModal: payload || !state.openSBPModal,
});

const paymentCardSuccess = (state, payload) => ({
  ...state,
  type: 'card',
  tds: { ...payload },
  loading: false,
});
const paymentCardFail = (state, payload) => ({
  ...state,
  error: payload,
  loading: false,
  status: 6,
  isSubmitting: false,
});
const paymentMonetaSuccess = (state, payload) => ({
  ...state,
  type: 'card',
  monetaLink: payload.link,
  extra: {
    method: payload.method || 'post',
    type: payload.type || 'iframe',
  },
  loading: false,
});
const paymentMonetaFail = (state, payload) => ({
  ...state,
  error: payload,
  loading: false,
  status: 6,
  isSubmitting: false,
});

const PAYMENT_CARD = 'PAYMENT_CARD';
const PAYMENT_CARD_SUCCESS = 'PAYMENT_CARD_SUCCESS';
const PAYMENT_CARD_FAIL = 'PAYMENT_CARD_FAIL';
const CLEAR_PAYMENT = 'CLEAR_PAYMENT';
const PAYMENT_SBP = 'PAYMENT_SBP';
const PAYMENT_SBP_SUCCESS = 'PAYMENT_SBP_SUCCESS';
const PAYMENT_SBP_FAIL = 'PAYMENT_SBP_FAIL';
const TOGGLE_OPEN_SBP_MODAL = 'TOGGLE_OPEN_SBP_MODAL';
const PAYMENT_SAMSUNG_PAY = 'PAYMENT_SAMSUNG_PAY';
const PAYMENT_SAMSUNG_PAY_SUCCESS = 'PAYMENT_SAMSUNG_PAY_SUCCESS';
const PAYMENT_SAMSUNG_PAY_FAIL = 'PAYMENT_SAMSUNG_PAY_FAIL';
const PAYMENT_APPLE_PAY = 'PAYMENT_APPLE_PAY';
const PAYMENT_APPLE_PAY_SUCCESS = 'PAYMENT_APPLE_PAY_SUCCESS';
const PAYMENT_APPLE_PAY_FAIL = 'PAYMENT_APPLE_PAY_FAIL';
const PAYMENT_GOOGLE_PAY = 'PAYMENT_GOOGLE_PAY';
const PAYMENT_GOOGLE_PAY_SUCCESS = 'PAYMENT_GOOGLE_PAY_SUCCESS';
const PAYMENT_GOOGLE_PAY_FAIL = 'PAYMENT_GOOGLE_PAY_FAIL';
const PAYMENT_MONETA = 'PAYMENT_MONETA';
const PAYMENT_MONETA_SUCCESS = 'PAYMENT_MONETA_SUCCESS';
const PAYMENT_MONETA_FAIL = 'PAYMENT_MONETA_FAIL';

function payment(state = initialState, action) {
  switch (action.type) {
    case CLEAR_PAYMENT: return clearPayment();
    case PAYMENT_CARD: return paymentCard(state);
    case PAYMENT_CARD_SUCCESS: return paymentCardSuccess(state, action.payload);
    case PAYMENT_CARD_FAIL: return paymentCardFail(state, action.payload);
    case PAYMENT_SBP: return paymentSBP(state);
    case PAYMENT_SBP_SUCCESS: return paymentSBPSuccess(state, action.payload);
    case PAYMENT_SBP_FAIL: return paymentSBPFail(state, action.payload);
    case PAYMENT_SAMSUNG_PAY: return paymentSamsungPay(state);
    case PAYMENT_SAMSUNG_PAY_SUCCESS: return paymentSamsungPaySuccess(state, action.payload);
    case PAYMENT_SAMSUNG_PAY_FAIL: return paymentSamsungPayFail(state, action.payload);
    case PAYMENT_APPLE_PAY: return paymentSamsungPay(state);
    case PAYMENT_APPLE_PAY_SUCCESS: return { ...state, ...action.payload, loading: false };
    case PAYMENT_APPLE_PAY_FAIL: return { ...state, error: action.payload, loading: false };
    case PAYMENT_GOOGLE_PAY: return { ...state };
    case PAYMENT_GOOGLE_PAY_SUCCESS: return { ...state, ...action.payload };
    case PAYMENT_GOOGLE_PAY_FAIL: return { ...state, error: action.payload };
    case TOGGLE_OPEN_SBP_MODAL: return toggleOpenSBPModal(state, action.payload);
    case PAYMENT_MONETA: return paymentCard(state);
    case PAYMENT_MONETA_SUCCESS: return paymentMonetaSuccess(state, action.payload);
    case PAYMENT_MONETA_FAIL: return paymentMonetaFail(state, action.payload);
    default: return state;
  }
}
export default payment;
